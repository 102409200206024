import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Col, Container, Row } from 'react-bootstrap';
import { black, hover } from '../styles/globalStyleVars';
import { CSSPlugin, gsap, TimelineLite } from 'gsap';
import { Img } from './Img';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.min.css';
// import disableScroll from 'disable-scroll';
import { Link, useLocation } from 'react-router-dom';
import Logo from './svg/Logo';
import HamubrerLine from './svg/HamubrerLine';
import MenuClose from './svg/MenuClose';
import Social from './Social';

const MyComponent = ({ offset }) => {
  gsap.registerPlugin(CSSPlugin);
  const router = useLocation();
  let tl = new TimelineLite();
  let tl2 = new TimelineLite();
  let tll = new TimelineLite();
  let [videoId, setVideo] = useState('');
  let [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState('');
  let handelOpen = (open, id) => {
    setOpen(open);
    setVideo(id);
  };
  // remove address bar hight from mobile
  useEffect(() => {
    // Get the viewport height and multiply it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;

    // Set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [null]); // Empty dependency array to run this effect only once



  //desktop menu contact dropdown
  useEffect(() => {
    document.querySelector(`.click-dropdown`)?.addEventListener('click', () => {
      if (!document.querySelector('.main-menu__contact').classList.contains('open-dropdown')) {
        document.querySelector('.main-menu__contact').classList.add('open-dropdown');
        tl.to('.dropdown-list', {
          duration: .2,
          display: 'block',
        }).to('.dropdown-list', {
          y: '20px',
          duration: .4,
          height: 'calc(100% - 20px)',
          alpha: 1,
          paddingTop: '33px',
          paddingBottom: '15px',
          ease: 'circ.out',
        });
      } else {
        document.querySelector('.main-menu__contact').classList.remove('open-dropdown');
        tl.to('.dropdown-list', {
          duration: .3,
          height: '0%',
          alpha: 0,
          paddingTop: '0',
          y: '0px',
          ease: 'circ.out',
        }).to('.dropdown-list', {
          duration: .2,
          display: 'none',
        });
      }
    });
    window.addEventListener('click', (e) => {
      if (document.querySelector('.main-menu__contact') && document.querySelector('.main-menu__contact').classList.contains('open-dropdown')) {
        if (!e.target.matches('.click-dropdown, .click-dropdown p, .click-dropdown svg, .click-dropdown line')) {
          tl.to('.dropdown-list', {
            duration: .3,
            height: '0%',
            alpha: 0,
            paddingTop: '0',
            y: '0px',
            ease: 'circ.out',
          }).to('.dropdown-list', {
            duration: .2,
            display: 'none',
          });

          document.querySelector('.main-menu__contact').classList.remove('open-dropdown');
        }
      }
    });
  }, []);

  //desktop menu on click toggle
  useEffect(() => {
    document.querySelector('.main-menu__menu__inner').addEventListener('click', () => {
      // disableScroll.on()
      if (window.safari !== undefined) {
        document.body.classList.add('overflow');
      }
      document.querySelector('.main-menu').classList.add('menu-is-open');
      document.body.classList.add('stop-scroll');
      tll.to('.main-menu__items', {
        duration: '0',
        display: 'block',
      }).to('.main-menu__items', {
        opacity: '1',
        duration: '.6',
      }).to('.main-menu__items__img', {
        opacity: '1',
        duration: '.3',
      }, '-=.5').to('.main-menu__items__list', {
        duration: .5,
        y: 0,
        alpha: 1,
      }, '-=.5');
    });

    // desktop menu close
    const closeDelstopMenu = () => {
      tll.to('.main-menu__items__list', {
        duration: .2,
        y: '-40px',
        alpha: 0,
      }).to('.main-menu__items', {
        opacity: '0',
        duration: '.3',
      }).to('.main-menu__items__img', {
        opacity: '0',
        duration: '.2',
      }, '-=.5').to('.main-menu__items__list', {
        duration: .2,
        y: '40px',
        alpha: 0,
      }, '-=.3').to('.main-menu__items', {
        duration: '.4',
        opacity: 0,
      }).to('.main-menu__items', {
        display: 'none',
      });
    };
    document.querySelector('.menu-close').addEventListener('click', () => {
      document.querySelector('.main-menu').classList.remove('menu-is-open');
      closeDelstopMenu();
      // disableScroll.off()
      if (document.body.classList.contains('overflow')) {
        document.body.classList.remove('overflow');
      }
      if (document.body.classList.contains('stop-scroll')) {
        document.body.classList.remove('stop-scroll');
      }
    });

  }, []);


  //desktop menu hover action
  useEffect(() => {
    let getLis = document.querySelectorAll('.menu-col li a'),
      getImgs = document.querySelectorAll('.main-menu__items__img li');
    for (var i = 0; i < getLis.length; i++) {
      getLis[i].setAttribute('data-index', i);

      getLis[i].onmouseover = function() {
        document.querySelector('.main-menu__items__img').classList.remove('parent-add');
        getImgs.forEach(e => {
          e.classList.remove('active');
        });
        getImgs[this.getAttribute('data-index')].classList.add('active');
        setTimeout(() => {
          document.querySelector('.main-menu__items__img').classList.add('parent-add');
        }, 200);
      };
    }
  }, []);


  //----- mobile menu action
  useEffect(() => {
    let getMobileMenuBar = document.querySelector('.main-menu-mobile');
    let getItemsParent = document.querySelector('.main-menu-mobile__items');
    let getItems = document.querySelectorAll('.main-item');
    let getBacks = document.querySelectorAll('.sub-menu-back');
    let getHamburgerClick = document.querySelector('#open-click');
    let getHamburgerCloseClick = document.querySelector('#close-click');

    // menu open toggle
    getHamburgerClick.addEventListener('click', () => {
      document.body.classList.add('menu-on');
      document.querySelector('.main-menu-mobile').classList.add('menu-is-open');
      getMobileMenuBar.classList.add('menu-open');
      document.body.classList.add('stop-scroll');
      tl2.to(getItemsParent, {
        duration: .2,
        display: 'block',
      }).to(getItemsParent, {
        duration: .2,
        x: 0,
      }, '-=.2');
    });

    getHamburgerCloseClick?.addEventListener('click', () => {
      getMobileMenuBar.classList.remove('menu-open');
      document.body.classList.remove('menu-on');
      document.querySelector('.main-menu-mobile').classList.remove('menu-is-open');
      document.body.classList.remove('stop-scroll');
      if (document.querySelector('.main-item.active')) {
        getItemsParent.classList.remove('active');
        document.querySelector('.main-item.active').classList.remove('active');
      }

      tl2.to(getItemsParent, {
        duration: .2,
        x: '100%',
      }).to(getItemsParent, {
        duration: .2,
        display: 'none',
      });
    });


    // sub menu toggle
    getItems.forEach(i => {
      i.addEventListener('click', () => {
        getItemsParent.classList.add('active');
        i.classList.add('active');
      });
    });

    getBacks.forEach(i => {
      i.addEventListener('click', (e) => {

        getItemsParent.classList.remove('active');
        i.parentNode.parentElement.classList.remove('active');
        e.stopPropagation();
      });
    });

  }, []);


  // menu fixed on scroll
  useEffect(() => {
    if (document.body.classList.contains('scroll-down')) {
      document.body.classList.remove('scroll-down');
    }
  });

  useEffect(() => {
    const whichPage = router.pathname;
  }, [router?.pathname]);

  const [scrollUp, setScrollUp] = useState(false);
  const [scrollDown, setScrollDown] = useState(false);
  useEffect(() => {
    const body = document.body;
    const scrollUp = 'scroll-up';
    const scrollDown = 'scroll-down';
    let lastScroll = 0;
    let howMuchScroll = 20;

    if (document.querySelector('.main-menu').classList.contains('menu-is-open')) {

    } else {
      window.addEventListener('scroll', () => {
        let currentScroll = window.pageYOffset;

        if (currentScroll <= howMuchScroll) {
          // body.classList.remove(scrollUp);
          setScrollUp(false);
          setScrollDown(false);

          return;
        }
        if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
          // down
          // body.classList.remove(scrollUp);
          // body.classList.add(scrollDown);
          setScrollDown(true);
          setScrollUp(false);

        } else if (
          currentScroll < lastScroll
          // body.classList.contains(scrollDown)
        ) {
          // up
          // body.classList.remove(scrollDown);
          // body.classList.add(scrollUp);
          setScrollUp(true);
          setScrollDown(false);

        }
        lastScroll = currentScroll;
      });

    }
  }, []);


  return (
    <>
      {/* desktop menu*/}
      <StyledMenu offset={offset}
                  className={`main-menu menu-bar ${scrollDown ? 'scroll-down' : ''} ${scrollUp ? 'scroll-up' : ''} `}>
        {/*menu bar*/}
        <Container>
          <Row>

            <Col className={'main-menu__logo justify-content-start d-flex'}>
              <Link to={'/'}>
                <Logo />
              </Link>
            </Col>

            <Col className={'main-menu__menu'}>
              <ul className={'short-menu'}>
                <li className={`${router.pathname === '/or-sale' ? 'active' : ''}`}>
                  <Link to={'/for-sale'}>
                    For Sale
                  </Link>
                </li>
                <li className={`${router.pathname === '/project' ? 'active' : ''}`}>
                  <Link to={'/project'}>
                    Projects
                  </Link>
                </li>
                <li className={`${router.pathname === '/contact-us' ? 'active' : ''}`}>
                  <Link to={'/contact-us'}>
                    Contact
                  </Link>
                </li>
              </ul>
              <div className='main-menu__menu__inner'>
                <div className='wrap'>
                  <HamubrerLine />
                </div>
              </div>

            </Col>
          </Row>
        </Container>

        {/*menu items*/}
        <div className='main-menu__items'>
          <div className='noise'></div>

          <div className='d-flex'>
            {/*menu items right*/}
            <Col sm={7} className={'p-0'}>
              <div className='main-menu__items__list d-flex'>
                <div className='menu-close '>
                  <MenuClose />

                </div>
                <Col className={'menu-col'} sm={{ span: 5 }}>
                  <ul>
                    <li className={`${router.pathname === '/' ? 'active' : ''}`}><Link
                      to='/'>Home</Link></li>
                    <li className={`${router.pathname === '/about' ? 'active' : ''}`}><Link
                      to='/about'>About Us</Link></li>
                    <li className={`${router.pathname === '/services' ? 'active' : ''}`}><Link
                      to='/services'>Services</Link></li>
                    <li className={`${router.pathname === '/consultancy' ? 'active' : ''}`}><Link
                      to='/consultancy'>Consultancy</Link></li>
                    <li className={`${router.pathname === '/concerns' ? 'active' : ''}`}><Link
                      to='/concerns'>Concerns</Link>
                    </li>
                    <li className={`${router.pathname === '/csr' ? 'active' : ''}`}><Link
                      to='/csr'>CSR</Link></li>
                    <li className={`${router.pathname === '/career' ? 'active' : ''}`}><Link
                      to='/career'>Career</Link></li>
                    <li className={`${router.pathname === '/contact-us' ? 'active' : ''}`}><Link
                      to='/contact-us'>Contact</Link></li>
                  </ul>
                </Col>
                <Col className={'menu-col'} sm={5}>
                  <ul>
                    <li className={`${router.pathname === '/project' ? 'active' : ''}`}>
                      <Link to='/project'>Projects</Link></li>
                    <li className={`${router.pathname === '/for-sale' ? 'active' : ''}`}>
                      <Link to='/for-sale'>For Sale</Link></li>
                  </ul>
                </Col>

                <div className='main-menu__items__list__bottom row'>
                  <div className='copyright col-md-6'>
                    <p><a
                        href='https://www.google.com/maps/place/Formonic/@23.8006716,90.4207038,17z/data=!4m14!1m7!3m6!1s0x3755c76a73c616b7:0x2b652b692ac47b4f!2sFormonic!8m2!3d23.8006667!4d90.4232787!16s%2Fg%2F11h4nhy3mb!3m5!1s0x3755c76a73c616b7:0x2b652b692ac47b4f!8m2!3d23.8006667!4d90.4232787!16s%2Fg%2F11h4nhy3mb?entry=ttu'
                        target={'_blank'}>
                      Business Point, Level-5, Plot no-77&78,<br/>
                      Road No-3&4 (Main Road), Block-I,<br/>
                      Bashundhara-R/A, Dhaka-1229


                    </a></p>

                  </div>
                  <div className='copyright col-md-6'>
                    <p><a href='tel:+8801785-888000'>
                      +8801785-888000
                    </a></p>
                    <p><a href='mailto:hanif07buet@gmail.com'>
                      hanif07buet@gmail.com
                    </a></p>
                    <ul class={'social-list'}>
                      <li>
                        <a href='' target={'_blank'}>
                          <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30'
                               viewBox='0 0 30 30'>
                            <g id='Group_22574' data-name='Group 22574'
                               transform='translate(13704 -5678)'>
                              <path id='Path_26' data-name='Path 26'
                                    d='M1013.328,3919.447v-1.17a2.525,2.525,0,0,1,.025-.408.792.792,0,0,1,.111-.285.454.454,0,0,1,.28-.193,1.98,1.98,0,0,1,.516-.054h1.17v-2.341h-1.871a3.046,3.046,0,0,0-2.333.771,3.248,3.248,0,0,0-.709,2.271v1.41h-1.4v2.341h1.4v6.793h2.811v-6.793h1.872l.247-2.341Z'
                                    transform='translate(-14701.282 1771.213)'
                                    fill='#7e7576' />
                            </g>
                          </svg>

                        </a>
                      </li>
                      <li>
                        <a href='' target={'_blank'}>
                          <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30'
                               viewBox='0 0 30 30'>
                            <g id='Group_22573' data-name='Group 22573'
                               transform='translate(13668 -5678)'>

                              <g id='Group_28' data-name='Group 28'
                                 transform='translate(-13660.148 5685.5)'>
                                <path id='LinkedIn'
                                      d='M1101.732,3924.682v5.286h-3.064v-4.932c0-1.239-.443-2.084-1.553-2.084a1.676,1.676,0,0,0-1.572,1.121,2.1,2.1,0,0,0-.1.748v5.147h-3.066s.041-8.353,0-9.218h3.065v1.307c-.006.009-.014.02-.02.029h.02v-.029a3.043,3.043,0,0,1,2.762-1.523C1100.219,3920.533,1101.732,3921.851,1101.732,3924.682Zm-12.563-8.375a1.6,1.6,0,1,0-.041,3.186h.021a1.6,1.6,0,1,0,.02-3.186Zm-1.552,13.661h3.064v-9.218h-3.064Z'
                                      transform='translate(-1087.435 -3916.306)'
                                      fill='#7e7576' />
                              </g>
                            </g>
                          </svg>

                        </a>
                      </li>
                      <li>
                        <a href='' target={'_blank'}>
                          <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30'
                               viewBox='0 0 30 30'>
                            <g id='Group_22572' data-name='Group 22572'
                               transform='translate(13632 -5678)'>

                              <path id='Icon_awesome-instagram'
                                    data-name='Icon awesome-instagram'
                                    d='M6.628,5.468a3.4,3.4,0,1,0,3.4,3.4A3.395,3.395,0,0,0,6.628,5.468Zm0,5.61a2.21,2.21,0,1,1,2.21-2.21,2.214,2.214,0,0,1-2.21,2.21ZM10.96,5.329a.793.793,0,1,1-.793-.793A.791.791,0,0,1,10.96,5.329Zm2.252.8a3.924,3.924,0,0,0-1.071-2.779A3.95,3.95,0,0,0,9.362,2.284c-1.095-.062-4.376-.062-5.471,0A3.945,3.945,0,0,0,1.112,3.352,3.937,3.937,0,0,0,.041,6.131c-.062,1.095-.062,4.376,0,5.471a3.924,3.924,0,0,0,1.071,2.778,3.955,3.955,0,0,0,2.779,1.071c1.095.062,4.376.062,5.471,0a3.924,3.924,0,0,0,2.779-1.071A3.95,3.95,0,0,0,13.212,11.6c.062-1.095.062-4.373,0-5.468ZM11.8,12.777a2.238,2.238,0,0,1-1.261,1.261,14.615,14.615,0,0,1-3.909.266,14.729,14.729,0,0,1-3.909-.266,2.238,2.238,0,0,1-1.261-1.261,14.614,14.614,0,0,1-.266-3.909,14.728,14.728,0,0,1,.266-3.909A2.238,2.238,0,0,1,2.719,3.7a14.614,14.614,0,0,1,3.909-.266,14.728,14.728,0,0,1,3.909.266A2.238,2.238,0,0,1,11.8,4.959a14.614,14.614,0,0,1,.266,3.909A14.606,14.606,0,0,1,11.8,12.777Z'
                                    transform='translate(-13623.627 5684.131)'
                                    fill='#7e7576' />
                            </g>
                          </svg>

                        </a>
                      </li>
                      <li>
                        <a href='' target={'_blank'}>
                          <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30'
                               viewBox='0 0 30 30'>
                            <g id='Group_22571' data-name='Group 22571'
                               transform='translate(13596 -5678)'>

                              <g id='Group_31' data-name='Group 31'
                                 transform='translate(-13588.5 5688.5)'>
                                <path id='Path_29' data-name='Path 29'
                                      d='M1180.424,3919.7c-.523-.622-1.49-.875-3.336-.875h-6.7c-1.888,0-2.87.271-3.392.933-.509.646-.509,1.6-.509,2.915v2.511c0,2.552.6,3.847,3.9,3.847h6.7c1.6,0,2.488-.224,3.061-.773.589-.563.84-1.482.84-3.074v-2.511C1180.989,3921.283,1180.95,3920.325,1180.424,3919.7Zm-4.627,4.574-3.043,1.591a.468.468,0,0,1-.684-.415v-3.17a.469.469,0,0,1,.684-.416l3.042,1.58a.468.468,0,0,1,0,.83Z'
                                      transform='translate(-1166.487 -3918.824)'
                                      fill='#7e7576' />
                              </g>
                            </g>
                          </svg>

                        </a>
                      </li>
                      <li>
                        <a href='' target={'_blank'}>
                          <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30'
                               viewBox='0 0 30 30'>
                            <g id='Group_22570' data-name='Group 22570'
                               transform='translate(13560 -5678)'>

                              <path id='Icon_awesome-twitter'
                                    data-name='Icon awesome-twitter'
                                    d='M12.948,6.3c.009.128.009.256.009.385A8.358,8.358,0,0,1,4.542,15.1,8.358,8.358,0,0,1,0,13.774a6.119,6.119,0,0,0,.714.037,5.924,5.924,0,0,0,3.672-1.264A2.963,2.963,0,0,1,1.621,10.5a3.73,3.73,0,0,0,.559.046,3.128,3.128,0,0,0,.778-.1,2.958,2.958,0,0,1-2.372-2.9V7.5a2.979,2.979,0,0,0,1.337.375,2.962,2.962,0,0,1-.916-3.956,8.407,8.407,0,0,0,6.1,3.1,3.339,3.339,0,0,1-.073-.678,2.961,2.961,0,0,1,5.119-2.024A5.823,5.823,0,0,0,14.029,3.6a2.95,2.95,0,0,1-1.3,1.63,5.929,5.929,0,0,0,1.7-.458A6.358,6.358,0,0,1,12.948,6.3Z'
                                    transform='translate(-13552.5 5683.619)'
                                    fill='#7e7576' />
                            </g>
                          </svg>

                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>

            {/*menu left img*/}
            <Col sm={5} className={'p-0'}>
              <div className='main-menu__items__img'>
                <li className={'active'}><Img src={'/images/dynamic/menu1.jpg'} /></li>
                <li><Img src={'/images/dynamic/sliderbanner.jpg'} /></li>
                <li><Img src={'/images/dynamic/menu1.jpg'} /></li>
                <li><Img src={'/images/dynamic/sliderbanner.jpg'} /></li>
                <li><Img src={'/images/dynamic/menu1.jpg'} /></li>
                <li><Img src={'/images/dynamic/sliderbanner.jpg'} /></li>
                <li><Img src={'/images/dynamic/menu1.jpg'} /></li>
                <li><Img src={'/images/dynamic/sliderbanner.jpg'} /></li>
                <li><Img src={'/images/dynamic/menu1.jpg'} /></li>
                <li><Img src={'/images/dynamic/sliderbanner.jpg'} /></li>
                <li><Img src={'/images/dynamic/menu1.jpg'} /></li>
                <li><Img src={'/images/dynamic/sliderbanner.jpg'} /></li>

              </div>
            </Col>


          </div>

        </div>
      </StyledMenu>

      {/*mobile menu*/}
      <StyledMobileMenu
        className={`main-menu-mobile ${scrollDown ? 'scroll-down-mb' : ''} ${scrollUp ? 'scroll-up-mb' : ''}`}>


        <div className='main-menu-mobile__bar'>
          {/*sound toggle */}
          <div className='mute-toggle-mobile'>

            <svg width='16.4' height='16'>
              <g id='Group_16461' data-name='Group 16461' transform='translate(-150 1177)'>
                <g id='Group_16460' data-name='Group 16460' transform='translate(-11 -1)'>
                  <g id='Group_16459' data-name='Group 16459'
                     transform='translate(-63 -1208)'>
                    <path id='volume'
                          d='M9.229,0A6.557,6.557,0,0,0,4.591,1.921L2.713,3.8.345,4.983A.625.625,0,0,0,0,5.542v4.917a.625.625,0,0,0,.346.559L2.713,12.2l1.878,1.877A6.557,6.557,0,0,0,9.229,16a.625.625,0,0,0,.625-.625V.625A.625.625,0,0,0,9.229,0ZM8.6,14.714a5.269,5.269,0,0,1-3.13-1.519C3.4,11.132,3.524,11.209,3.363,11.129L1.25,10.072V5.928L3.363,4.872A19.679,19.679,0,0,0,5.474,2.805,5.27,5.27,0,0,1,8.6,1.286Z'
                          transform='translate(224 32)' fill='#34342e' />
                    <line id='Line_13' data-name='Line 13' y1='5'
                          transform='translate(236.9 37.5)' fill='none' stroke='#34342e'
                          stroke-linecap='round' stroke-width='1' />
                    <line id='Line_14' data-name='Line 14' y1='10'
                          transform='translate(239.9 34.5)' fill='none' stroke='#34342e'
                          stroke-linecap='round' stroke-width='1' />
                  </g>
                </g>
              </g>
            </svg>

          </div>


          <div className='main-menu-mobile__bar__logo'>
            <Link to={'/'}>
              <Logo />
            </Link>
          </div>

          <div className='main-menu-mobile__bar__hamburger d-flex justify-content-end'>
            <div className='' id={'open-click'}>
              <HamubrerLine />
            </div>
            <div id={'close-click'}>
              <svg xmlns='http://www.w3.org/2000/svg' width='15.557' height='15.557'
                   viewBox='0 0 15.557 15.557'>
                <g id='Group_22584' data-name='Group 22584' transform='translate(-1253.15 -32.222)'>
                  <line id='Line_12397' data-name='Line 12397' x1='20'
                        transform='translate(1253.857 32.929) rotate(45)' fill='none' stroke='#f1eee9'
                        stroke-linecap='round' stroke-width='1' />
                  <line id='Line_12403' data-name='Line 12403' x1='20'
                        transform='translate(1268 32.929) rotate(135)' fill='none' stroke='#f1eee9'
                        stroke-linecap='round' stroke-width='1' />
                </g>
              </svg>

            </div>
          </div>
        </div>

        <div className='main-menu-mobile__items'>
          <div className='noise'></div>

          <div className='main-menu-mobile__items__head'>

          </div>
          <div className='main-menu-mobile__items__body'>
            <ul className={'menu-list'}>

              <li className={`${router.pathname === '/' ? 'active' : ''}`}><Link to='/'>Home</Link>
              </li>
              <li className={`${router.pathname === '/about' ? 'active' : ''}`}><Link
                to='/about'>About US</Link></li>
              <li className={`${router.pathname === '/project' ? 'active' : ''}`}><Link
                to='/project'>Projects</Link></li>
              <li className={`${router.asPath === '/for-sale' ? 'active' : ''}`}><Link
                to='/for-sale'>For Sale</Link></li>
              <li className={`${router.pathname === '/services' ? 'active' : ''}`}><Link
                to='/services'>Services</Link></li>
              <li className={`${router.pathname === '/consultancy' ? 'active' : ''}`}><Link
                to='/consultancy'>Consultancy</Link>
              </li>
              <li className={`${router.pathname === '/concerns' ? 'active' : ''}`}><Link
                to='/concerns'>Concerns</Link>
              </li>
              <li className={`${router.pathname === '/csr' ? 'active' : ''}`}><Link
                to='/csr'>CSR</Link></li>
              <li className={`${router.pathname === '/career' ? 'active' : ''}`}><Link
                to='/career'>Career</Link></li>
              <li className={`${router.pathname === '/contact-us' ? 'active' : ''}`}><Link
                to='/contact-us'>Contact</Link></li>



            </ul>

            <div className='bottom'>
              <p>
                <a
                  href='https://www.google.com/maps/place/Formonic/@23.8006716,90.4207038,17z/data=!4m14!1m7!3m6!1s0x3755c76a73c616b7:0x2b652b692ac47b4f!2sFormonic!8m2!3d23.8006667!4d90.4232787!16s%2Fg%2F11h4nhy3mb!3m5!1s0x3755c76a73c616b7:0x2b652b692ac47b4f!8m2!3d23.8006667!4d90.4232787!16s%2Fg%2F11h4nhy3mb?entry=ttu'
                  target={''}>
                  Family Park-1(5th floor), Suit #B-6 & #B-8, Road #07, Block #J, Baridhara,
                  Dhaka-1212
                </a>
              </p>
              <p>
                <a href='tel:+8801785-888000'>+8801785-888000</a>
              </p>
              <p>
                <a href='mailto:hanif07buet@gmail.com'>hanif07buet@gmail.com</a>
              </p>
              <ul className={'social-list'}>
                <li>
                  <a href='' target={'_blank'}>
                    <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30'
                         viewBox='0 0 30 30'>
                      <g id='Group_22574' data-name='Group 22574'
                         transform='translate(13704 -5678)'>
                        <path id='Path_26' data-name='Path 26'
                              d='M1013.328,3919.447v-1.17a2.525,2.525,0,0,1,.025-.408.792.792,0,0,1,.111-.285.454.454,0,0,1,.28-.193,1.98,1.98,0,0,1,.516-.054h1.17v-2.341h-1.871a3.046,3.046,0,0,0-2.333.771,3.248,3.248,0,0,0-.709,2.271v1.41h-1.4v2.341h1.4v6.793h2.811v-6.793h1.872l.247-2.341Z'
                              transform='translate(-14701.282 1771.213)'
                              fill='#7e7576' />
                      </g>
                    </svg>

                  </a>
                </li>
                <li>
                  <a href='' target={'_blank'}>
                    <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30'
                         viewBox='0 0 30 30'>
                      <g id='Group_22573' data-name='Group 22573'
                         transform='translate(13668 -5678)'>

                        <g id='Group_28' data-name='Group 28'
                           transform='translate(-13660.148 5685.5)'>
                          <path id='LinkedIn'
                                d='M1101.732,3924.682v5.286h-3.064v-4.932c0-1.239-.443-2.084-1.553-2.084a1.676,1.676,0,0,0-1.572,1.121,2.1,2.1,0,0,0-.1.748v5.147h-3.066s.041-8.353,0-9.218h3.065v1.307c-.006.009-.014.02-.02.029h.02v-.029a3.043,3.043,0,0,1,2.762-1.523C1100.219,3920.533,1101.732,3921.851,1101.732,3924.682Zm-12.563-8.375a1.6,1.6,0,1,0-.041,3.186h.021a1.6,1.6,0,1,0,.02-3.186Zm-1.552,13.661h3.064v-9.218h-3.064Z'
                                transform='translate(-1087.435 -3916.306)'
                                fill='#7e7576' />
                        </g>
                      </g>
                    </svg>

                  </a>
                </li>
                <li>
                  <a href='' target={'_blank'}>
                    <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30'
                         viewBox='0 0 30 30'>
                      <g id='Group_22572' data-name='Group 22572'
                         transform='translate(13632 -5678)'>

                        <path id='Icon_awesome-instagram'
                              data-name='Icon awesome-instagram'
                              d='M6.628,5.468a3.4,3.4,0,1,0,3.4,3.4A3.395,3.395,0,0,0,6.628,5.468Zm0,5.61a2.21,2.21,0,1,1,2.21-2.21,2.214,2.214,0,0,1-2.21,2.21ZM10.96,5.329a.793.793,0,1,1-.793-.793A.791.791,0,0,1,10.96,5.329Zm2.252.8a3.924,3.924,0,0,0-1.071-2.779A3.95,3.95,0,0,0,9.362,2.284c-1.095-.062-4.376-.062-5.471,0A3.945,3.945,0,0,0,1.112,3.352,3.937,3.937,0,0,0,.041,6.131c-.062,1.095-.062,4.376,0,5.471a3.924,3.924,0,0,0,1.071,2.778,3.955,3.955,0,0,0,2.779,1.071c1.095.062,4.376.062,5.471,0a3.924,3.924,0,0,0,2.779-1.071A3.95,3.95,0,0,0,13.212,11.6c.062-1.095.062-4.373,0-5.468ZM11.8,12.777a2.238,2.238,0,0,1-1.261,1.261,14.615,14.615,0,0,1-3.909.266,14.729,14.729,0,0,1-3.909-.266,2.238,2.238,0,0,1-1.261-1.261,14.614,14.614,0,0,1-.266-3.909,14.728,14.728,0,0,1,.266-3.909A2.238,2.238,0,0,1,2.719,3.7a14.614,14.614,0,0,1,3.909-.266,14.728,14.728,0,0,1,3.909.266A2.238,2.238,0,0,1,11.8,4.959a14.614,14.614,0,0,1,.266,3.909A14.606,14.606,0,0,1,11.8,12.777Z'
                              transform='translate(-13623.627 5684.131)'
                              fill='#7e7576' />
                      </g>
                    </svg>

                  </a>
                </li>
                <li>
                  <a href='' target={'_blank'}>
                    <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30'
                         viewBox='0 0 30 30'>
                      <g id='Group_22571' data-name='Group 22571'
                         transform='translate(13596 -5678)'>

                        <g id='Group_31' data-name='Group 31'
                           transform='translate(-13588.5 5688.5)'>
                          <path id='Path_29' data-name='Path 29'
                                d='M1180.424,3919.7c-.523-.622-1.49-.875-3.336-.875h-6.7c-1.888,0-2.87.271-3.392.933-.509.646-.509,1.6-.509,2.915v2.511c0,2.552.6,3.847,3.9,3.847h6.7c1.6,0,2.488-.224,3.061-.773.589-.563.84-1.482.84-3.074v-2.511C1180.989,3921.283,1180.95,3920.325,1180.424,3919.7Zm-4.627,4.574-3.043,1.591a.468.468,0,0,1-.684-.415v-3.17a.469.469,0,0,1,.684-.416l3.042,1.58a.468.468,0,0,1,0,.83Z'
                                transform='translate(-1166.487 -3918.824)'
                                fill='#7e7576' />
                        </g>
                      </g>
                    </svg>

                  </a>
                </li>
                <li>
                  <a href='' target={'_blank'}>
                    <svg xmlns='http://www.w3.org/2000/svg' width='30' height='30'
                         viewBox='0 0 30 30'>
                      <g id='Group_22570' data-name='Group 22570'
                         transform='translate(13560 -5678)'>

                        <path id='Icon_awesome-twitter'
                              data-name='Icon awesome-twitter'
                              d='M12.948,6.3c.009.128.009.256.009.385A8.358,8.358,0,0,1,4.542,15.1,8.358,8.358,0,0,1,0,13.774a6.119,6.119,0,0,0,.714.037,5.924,5.924,0,0,0,3.672-1.264A2.963,2.963,0,0,1,1.621,10.5a3.73,3.73,0,0,0,.559.046,3.128,3.128,0,0,0,.778-.1,2.958,2.958,0,0,1-2.372-2.9V7.5a2.979,2.979,0,0,0,1.337.375,2.962,2.962,0,0,1-.916-3.956,8.407,8.407,0,0,0,6.1,3.1,3.339,3.339,0,0,1-.073-.678,2.961,2.961,0,0,1,5.119-2.024A5.823,5.823,0,0,0,14.029,3.6a2.95,2.95,0,0,1-1.3,1.63,5.929,5.929,0,0,0,1.7-.458A6.358,6.358,0,0,1,12.948,6.3Z'
                              transform='translate(-13552.5 5683.619)'
                              fill='#7e7576' />
                      </g>
                    </svg>

                  </a>
                </li>
              </ul>
            </div>
          </div>


        </div>

      </StyledMobileMenu>
    </>

  );
};

const StyledMenu = styled.section`
  height: 90px;
  padding: 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
  align-items: center;
  justify-content: center;
  display: flex;

  &.menu-is-open {
    transform: none !important;
  }

  @media (max-width: 989px) {
    display: none;
  }

  .logo {
    position: absolute;
    top: 40px;
    left: 98px;
    z-index: 5;
  }

  .main-menu__contact {
    height: fit-content;

    &__drop {
      width: 152px;
      height: 40px;
      background-color: ${black};
      border-radius: 20px;
      align-items: center;
      justify-content: center;
      display: flex;
      cursor: pointer;


      p {
        font-size: 20px;
        color: #ffffff;

        svg {
          margin-left: 10px;
        }
      }
    }

    &__list {
      background-color: #F2EEE8;
      padding: 0px 30px 0 30px;
      width: 260px;
      display: none;
      height: 0;
      overflow: hidden;


      a {
        color: ${hover};
        font-size: 16px;
        line-height: 24px;
        text-transform: none;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 23px;


        span {
          display: inline-flex;
          height: 24px;
          width: 24px;
          background: ${black};
          border-radius: 50%;
          align-items: center;
          justify-content: center;
        }

        &:hover {
          span:after {
            height: 0;
            width: 0;
          }
        }
      }

      ul {
        li {
          display: flex;
          padding-bottom: 17px;
          margin-bottom: 18px;
          border-bottom: 1px solid rgba(178, 168, 159, 0.20);
          flex-wrap: wrap;

          a {
            color: ${hover};
            font-size: 16px;
            line-height: 20px;
            display: flex;
            width: 100%;
            margin: 0;
            padding: 0;
            text-transform: lowercase;
            transition: letter-spacing 0.7s ease;

            &:hover {
              letter-spacing: 1px;
              color: ${hover};

            }
          }

          span {
            font-size: 12px;
            line-height: 24px;
            color: #B2A89F;
            width: 100%;
            padding-bottom: 2px;

          }

          &:nth-last-of-type(1) {
            padding: 0;
            margin: 0;
            border: none;
          }
        }
      }
    }

  }

  .main-menu__logo {
    align-items: center;

    svg {
      transition: all .3s ease;
      height: auto;
    }
  }

  .main-menu__menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .short-menu {
      display: flex;

      li {
        margin-left: 35px;

        a {
          font-size: 12px;
          font-weight: 500;
          line-height: 24px;
          color: #F1EEE9;
          text-transform: uppercase;
          position: relative;
        }

        &.active {
          a {
            &:after {
              position: absolute;
              content: '';
              left: 0;
              right: 0;
              bottom: 0;
              width: 100%;
              background: #F1EEE9;
              height: 1px;
            }
          }
        }
      }
    }

    &__inner {
      padding: 0px 0 5px 0;
      cursor: pointer;
      height: fit-content;
      transition: padding .4s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        margin-top: 1px;
        text-transform: uppercase;
        font-weight: bold;
        color: #F7F3F0;
        line-height: 18px;
        letter-spacing: 2px;
        transition: all .6s cubic-bezier(0.4, 0, 0, 1) 0s;;

      }

      .wrap {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        height: 18px;
        margin-left: 35px;
      }

      .line {
        height: 2px;
        background-color: white;
        margin-left: auto !important;
        border-radius: 5px;
        transform-origin: right;
        transition: width .6s cubic-bezier(0.4, 0, 0, 1) 0s;;
        width: 40px;

      }

      &:hover {
        p {
          color: #5C5550;
        }

        .line {
          background: #5C5550;

          &:nth-of-type(2) {
            width: 20px;
          }
        }
      }
    }

  }

  .main-menu__items {
    position: fixed;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    z-index: 999;
    top: 0;
    left: 0;
    display: none;
    opacity: 0;


    // overview_bg
    .noise {
      position: absolute;
      inset: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: #26201E;

      &:after {
        content: '';
        background-image: url('/images/static/background-noise.png');
        background-blend-mode: multiply;
        //animation: Noise_grain 5s infinite linear;
        //width: calc(100% + 500px);
        //height: calc(100% + 500px);
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;
        opacity: 0.3;
      }

      &:after {
        //animation: Noise_grain 5s infinite linear;
      }


    }


    .d-flex {
      width: 100%;
    }


    &__img {
      position: relative;
      height: 100vh; /* Fallback for browsers that do not support Custom Properties */
      height: calc(var(--vh, 1vh) * 100);
      overflow: hidden;
      background: #fff;
      //opacity: 0;

      //&:before {
      //  content: '';
      //  position: absolute;
      //  background-color: #FFF;
      //  top: 0;
      //  bottom: 0;
      //  //left: 0;
      //  //width: 100%;
      //  height: 100%;
      //  z-index: 4;
      //
      //}

      //&.parent-add {
      //  &:before {
      //    animation: mymove .8s cubic-bezier(0.4, 0, 0, 1);
      //  }
      //}

      //@keyframes mymove {
      //  0% {
      //    width: 0;
      //    left: 0
      //  }
      //  30% {
      //    width: 100%;
      //    left: 0
      //  }
      //  40% {
      //    left: 0;
      //    width: 100%;
      //  }
      //  60% {
      //    right: 0;
      //    width: 100%;
      //  }
      //  80% {
      //    left: auto
      //  }
      //  95% {
      //    width: 0;
      //    right: 0;
      //  }
      //  100% {
      //    width: 0;
      //    right: 0;
      //  }
      //}

      li {
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        //opacity: 0;
        //transform: scale(.8);
        //transition: all .1s cubic-bezier(0.4, 0, 0, 1);
        //transition-delay: .6s;
        //z-index: 1;
        //transform: translate3d(100%, 0, 0);
        img {
          //transform: translateX(100%);
          //transition: all .6s cubic-bezier(0.4, 0, 0, 1);
          transition: all .6s ease;
          opacity: 0;
        }


        //&:after {
        //  content: '';
        //  position: absolute;
        //  height: 100%;
        //  //width: 100%;
        //  background-color: #FFF;
        //  left: 0;
        //  top: 0;
        //  z-index: 3;
        //  //transition: all 3s ease;
        //  //transition-delay: .2s;
        //}

        &.active {
          //transform: none;
          opacity: 1;
          //width: 100%;
          //transition-delay: .9s !important;
          z-index: 3;
          //transition: transform .6s cubic-bezier(0.4, 0, 0, 1);
          //transition: width .6s cubic-bezier(0.4, 0, 0, 1);
          //transition: width .8s cubic-bezier(0.4, 0, 0, 1);
          //transform: scale(1);

          img {
            //transform: none;
            //transition-delay: .4s;
            opacity: 1;
          }

          &:after {
            //width: 0%;
            //transition-delay: .3s;
            //animation: mymove 1s cubic-bezier(0.4, 0, 0, 1);
          }
        }
      }
    }

    &__list {
      min-height: 100%;
      padding-top: 160px;
      transform: translateY(40px);
      opacity: 0;

      .menu-close {
        position: absolute;
        top: 40px;
        left: ${props => props.offset + 0}px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        cursor: pointer;
        z-index: 4;

        &:after {
          //z-index: 2;
          background-color: #F7F3F0;
          border: 1px solid #F7F3F0;
          border-radius: 50%;
        }


      }

      .menu-col {
        padding-left: ${props => props.offset + 0}px;
      }

      ul {
        li {
          margin-bottom: 25px;

          a {
            font-family: 'Orchidea Pro';
            font-size: 24px;
            line-height: 28px;
            font-weight: 300;
            color: rgba(241, 238, 233, 0.5);
            position: relative;
            display: inline-block;
            margin: 0;
            text-transform: uppercase;

            &:before {
              content: '';
              position: absolute;
              left: 0;
              height: 1px;
              background-color: ${hover};
              bottom: 0;
              width: 0;
              transition: 0.7s all ease;
            }

            &:hover {
              &:before {
                width: 100%;
              }

              color: ${hover} !important;
            }
          }

          &.active {
            a {
              color: ${hover} !important;

              pointer-events: none;

              &:before {
                width: 100%;
              }
            }
          }
        }
      }

      &__bottom {
        position: absolute;
        bottom: 60px;
        background-color: transparent;
          //width: calc(100% - ${props => props.offset + 0});
        padding-left: ${props => props.offset + 0}px;
        width: 100%;
        right: 0;
        margin: 0;
        //@media (max-height: 650px) {
        //  display: none;
        //}

        .copyright {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: flex-end;
          padding-left: 0;
          padding-right: 0;

          &:first-child {
            p {
              margin: 0;
            }
          }

          &:last-child {
            p {
              margin-bottom: 0px;
            }
          }

          p {
            color: rgba(241, 238, 233, 0.5);
          }

          a {
            color: rgba(241, 238, 233, 0.5);
          }

          .social-list {
            display: flex;
            width: 100%;
            margin-top: 10px;
            display: flex;
            margin-left: -10px;

            li {
              margin-bottom: 0;

              &:before {
                display: none !important;
              }

              a {
                &:before {
                  display: none !important;
                }

                svg {
                  position: relative;
                  z-index: 2;

                  g {
                  }
                }

                &:hover {
                  path {
                    fill: ${hover};
                  }
                }
              }

              .hover:after {
                background-color: ${hover};
              }

              &:not(:nth-last-of-type(1)) {
                margin-right: 20px;
              }
            }
          }
        }

        .video {

          .hover:after {
            background-color: #fff;
          }

          span {
            display: flex;
            height: 30px;
            width: 30px;
            border: 1px solid ${hover};
            align-items: center;
            justify-content: center;
            background-color: ${black};
            border-radius: 50%;
            margin-right: 13px;
            margin-top: -4px;

            path {
              transition: fill 0.7s ease;
            }

          }

          p {
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 20px;
            line-height: 24px;
            color: ${hover};

            &:hover {
              .hover:after {
                height: 0;
                width: 0;
              }

              path {
                fill: #FFF;
              }
            }
          }
        }

        .social {
          margin-top: 30px;

          ul {
            display: flex;

            li {
              a {
                margin: 0 30px 0 0;
                height: 45px;
                width: 45px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: ${black};
                padding: 0;

                &:after {
                  //display: none;
                }
              }

              &:nth-last-of-type(1) a {
                margin: 0;
              }
            }
          }
        }
      }
    }

  }

  .mute-toggle {
    position: absolute;
    left: 200px;
    top: 10px;
    //bottom: 0;
    margin: auto;
    cursor: pointer;
    height: fit-content;
    display: none;
    //align-items: center;

    line {
      display: none;
    }

    svg {
      cursor: pointer;
    }

    &.sound {
      line {
        display: block;
      }
    }
  }


  @media(width: 1366px) and (height:619px){
    .main-menu__items__list ul li{
      margin-bottom: 10px;
    }
    .main-menu__items__list ul li a{
      
    }
  }
  @media (max-height: 750px) {
    .main-menu__items__list {
      padding-top: 150px;
    }
  }
  @media (max-width: 1280px) and (min-width: 1024px) {
    .main-menu__items__list ul li {
      margin-bottom: 15px;

      a {
        font-size: 20px;
      }
    }
  }
`;

//----- mobile menu
const StyledMobileMenu = styled.section`
  position: fixed;
  width: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  transition: transform 0.2s ease;
  transition-delay: .3s;
  //background: rgba(0, 0, 0, 0.3);
  height: 60px;
  padding-bottom: 0;

  &:after {
    //content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(241, 238, 233, 0.2);
    height: 1px;
    width: calc(100% - 30px);
    z-index: 9999;
    margin: auto;
  }

  .mute-toggle-mobile {
    position: absolute;
    left: 15px;
    top: 12px;
    //bottom: 0;
    margin: auto;
    cursor: pointer;
    height: fit-content;
    display: none;
    //align-items: center;

    path {
      fill: ${hover}
    }

    line {
      display: none;
      stroke: ${hover};
    }

    svg {
      cursor: pointer;
    }

    &.sound {
      line {
        display: block;
      }
    }
  }

  &.menu-is-open {
    transform: none !important;

    #close-click {
      opacity: 1;
      display: block;
    }

    #open-click {
      opacity: 0;
      display: none;

    }
  }

  @media (min-width: 989px) {
    display: none;
  }

  #open-click {
    transition: all .4s ease;
    display: block;

  }

  #close-click {
    opacity: 0;
    display: none;

  }

  .main-menu-mobile__bar {

    display: flex;
    justify-content: space-between;
    //align-items: center;
      //border-bottom: 1px solid ${hover};
    padding: 0 15px;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    //background-color: rgba(34, 34, 34, 0.30);
    z-index: 9999;
    transition: all .4s ease;
    height: 60px;

    &__logo {
      position: relative;
      margin: auto;
      width: fit-content;

      img {
        height: 50px;
      }

    }

    &__hamburger {

      width: 100%;
      padding-top: 0;
      height: 100%;
      align-items: center;


      svg {
        height: 20px;
        opacity: 1;
        transition: 1s all ease;
      }
    }


  }


  .main-menu-mobile__items {
    padding: 60px 0 0;
    position: relative;
    transform-origin: top left;
    transition: all .3s ease-out;
    background-color: transparent;
    width: 100vw;
    transform: translateX(100%);
    display: none;
    z-index: 999;
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: hidden;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */





    // overview_bg
    .noise {
      position: absolute;
      inset: 0;
      overflow: hidden;
      width: 100%;
      height: 100%;
      background: #26201E;

      &:after {
        content: '';
        background-image: url('/images/static/background-noise.png');
        background-blend-mode: multiply;
        //animation: Noise_grain 5s infinite linear;
        //width: calc(100% + 500px);
        //height: calc(100% + 500px);
        width: 100%;
        height: 100%;
        position: absolute;
        inset: 0;
        opacity: 0.3;
      }

      &:after {
        //animation: Noise_grain 5s infinite linear;
      }


    }

    &__head {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      width: calc(100vw);
      top: 0px;
      left: 0px;
      height: 60px;
      align-items: center;
      padding-left: 15px;
      padding-right: 15px;
      background: transparent;


      #close-click {

      }

      .sub-menu-back {
        display: none;
      }
    }

    &__body {
      height: 100%;
      width: 100%;
      padding: 60px 20px;
      position: relative;
      right: 0;
      margin: 0 0 0 auto;
      @media(max-width: 400px){
        padding: 40px 20px;
      }
      ul {
        width: 100%;

        &.menu-list {
          //height: calc(100vh - 300px);
          //overflow-y: auto;
          //width: 100%;
          height: 100%;
          width: 100%;

          a {
            width: 100%;
            display: flex;
          }
        }

        .main-item {
          ul {
            li {
              a {
                pointer-events: auto !important;
              }

              &:nth-of-type(1) {
                a {
                  position: relative;
                  display: flex;


                  &:after {
                    content: '';
                    position: absolute;
                    height: 24px;
                    width: 24px;
                    background-color: ${hover};
                    right: -30px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    border-radius: 100%;
                    //background-image: url();
                    //background-repeat: no-repeat;
                    //background-position: center;
                  }
                }
              }


            }
          }
        }


        li {
          text-transform: capitalize;
          display: block;
          width: 100%;


          a {
            color: rgba(247, 243, 240, 0.5);
            font-size: 24px;
            line-height: 24px;
            font-weight: 300;
            font-family: 'Orchidea Pro';
            text-transform: uppercase;
            @media (max-width: 400px) {
              font-size: 18px;
              line-height: 25px;
            }
          }

          &:not(:nth-last-of-type(1)) {
            margin-bottom: 24px;
            @media (max-width: 400px) {
              margin-bottom: 10px;
            }
          }

          &.active {
            a {
              border-bottom: 1px solid #F7F3F0;
              width: fit-content;
              pointer-events: none;
            }
          }

          //sub menus
          ul {
            left: 0;
            list-style: none;
            margin: 0;
            position: absolute;
            top: 113px;
            padding: 0 15px;
            //height: 100vh;
            opacity: 0;
            transform: translate3d(100vw, 0, 0);
            transition: all .3s ease;
            //padding-bottom: 40px;
            width: 100vw;
            padding-bottom: 25px;
            height: auto !important;
            overflow: hidden !important;

            li {
              width: auto !important;
              background-color: transparent !important;
              height: auto !important;
              display: block !important;
              text-align: left !important;

              &:nth-of-type(1) {
                margin-bottom: 80px !important;
              }

              &:not(:nth-of-type(1)) {
                a {

                  text-transform: capitalize;
                  width: 100%;
                  display: flex;
                  flex-wrap: wrap;
                  font-size: 30px;
                  line-height: 30px;

                  span {
                    min-width: 100%;
                    font-size: 12px;
                    line-height: 23px;
                    display: block;
                    margin-bottom: 13px;
                    color: #b2a89f;
                  }
                }
              }

            }
          }

          &.active {
            ul {
              opacity: 1;
              z-index: 2;
            }

          }

        }
      }


      &.active {
        transform: translate3d(-100vw, 0, 0) !important;

        .main-menu-mobile__items__head {
          transform: translate3d(100vw, 0, 0) !important;

          .sub-menu-back {
            display: block;
          }

          a {
            display: none;
          }
        }
      }

      .bottom {
        width: calc(100vw - 30px);
        position: absolute;
        //bottom: 40px;

        //position: relative;
        bottom: 40px;
        //margin-top: 30px;

        p {
          &:first-child {
            margin-bottom: 20px;
          }

          margin: 0;

          a {
            color: rgba(241, 238, 233, 0.5);
            font-size: 12px;
            line-height: 24px;
            font-weight: 500;
          }
        }


        .social-list {
          margin-top: 10px;
          display: flex;
          margin-left: -10px;

          li {
            margin: 0 0 0 15px;
            width: auto;

            &:first-child {
              margin: 0;
            }

            a {
            }

            &:nth-last-of-type(1) a {
              margin: 0;
            }
          }
        }


      }
    }

  }



`;

export default React.memo(MyComponent);
